import { Box, useTheme } from "@mui/material";
import Header from "../../components/Header";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../theme";

const FAQ = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box m="20px">
      <Header title="FAQ" subtitle="Frequently Asked Questions Page" />

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            What is Delta Sigma Technologies?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          We are an Ed-Tech Startup with expertise in areas of Data Science, Machine Learning, Internet of Things, Robotics and VLSI Design.
          
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            What are the services provided by DST?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          We cater to providing training at School Level, Engineering Level and Post-graduate Level.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            Does DST provide Internships for Engineering Students?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, we do offer internships in Data Science and Full stack Web Development.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            Is DST hiring?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Please share your resume to deltasigmatechnologies@gmail.com, we will get back to you if your profile matches our requirements.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            How do I connect with DST? 
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Please share your query to deltasigmatechnologies@gmail.com, we will get back to you soon.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default FAQ;